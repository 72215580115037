import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../global.css"
import check from "../assets/verified (2).png"
import imgServices from "../assets/Operating system-rafiki.png"
import { Container } from "../components/ui"
import { Helmet } from "react-helmet"

export default function Services() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | Services</title>
        <link rel="canonical" href="https://aikate.eu/services" />
        <meta
          name="Our services"
          content="Software development, IoT solutions, Our services"
        />
        <meta
          name="description"
          content="Our company offers a wide range of solutions to help businesses and boost productivity. From network setup, hardware development to cloud migration and software development, we have the expertise to meet your IT needs. "
        ></meta>
      </Helmet>
      <div className="px-4 py-16  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="pt-20 pr-8 grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <h1 className="mb-4 text-5xl sm:text-4xl lg:text-7xl font-extrabold leading-none">
              Our services
            </h1>
            <p className="mb-6 text-3xl pt-4 text-white">
              Our services extend from equipment sales to support and
              development. Find the ideal solution for your company and evolve
              your business with the help of our expertise.
            </p>
            <div className="flex items-center space-x-4"></div>
          </div>
          <div>
            <img
              className="md:pl-10 sm:h-96"
              src={imgServices}
              alt="services"
            />
          </div>
        </div>
      </div>

      <Container width="normal">
        <div className="flex items-left justify-left pr-4 w-100 h-100 mb-4"></div>
        <div className="grid gap-10 pt-20 px-8 row-gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-4">
          <div className="max-w-md">
            <h6 className="mb-2 pt-2 font-bold text-[#005BEA] text-3xl leading-5">
              IT support
            </h6>
            <p className="mb-3 pt-4 text-xl text-white">
              Leave your worries to our experts. Check the functionality and
              maintenance of servers to reduce downtime and optimize your
              company. We also ensure data security and integrity by generating
              backups of your existing files.
            </p>
          </div>
          <div className="max-w-md">
            <h6 className="mb-2 font-bold pt-2 text-[#005BEA] text-3xl leading-5">
              Equipment
            </h6>
            <p className="mb-3 pt-4  text-xl text-white">
              With the evaluation of our experts, find the right hardware and
              software for your company needs.<br></br>
              We are HPE( server, storage, networking-Aruba), Cisco and
              Microsoft certified partners. We also offer Lenovo, Rittal and
              Vertiv.
            </p>
          </div>
          <div className="max-w-md">
            <h6 className="mb-2 font-bold text-[#005BEA]  text-3xl leading-6">
              Hardware development
            </h6>
            <p className="mb-3  text-xl text-white">
              We have experience in developing embedded systems especially IoT
              devices. Our engineers can handle all technical challenges for
              your existing technology architecture for flawless performance and
              your whole unique IoT product.
            </p>
          </div>
          <div className="max-w-md">
            <h6 className="mb-2 font-semibold text-[#005BEA]  text-3xl leading-6">
              Viber business messaging
            </h6>
            <p className="mb-3 text-xl text-white">
              We offer personalized Viber messages to your audience over your
              personal branded Viber account. Reach a larger audience and
              deliver your message, promotion, giveaway, or any kind of
              marketing idea. Deliver important messages or provide customer
              support which will be available to your users whenever they need
              it.
            </p>
          </div>
        </div>
      </Container>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid grid-cols-1 gap-3 row-gap-3 sm:grid-cols-1 lg:grid-cols-4">
          <div className="text-center">
            <div className="circle flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full  sm:w-12 sm:h-12">
              <svg
                className="svg-icon w-8 h-8"
                fill="#005BEA"
                viewBox="0 0 20 20"
              >
                <path d="M17.283,5.549h-5.26V4.335c0-0.222-0.183-0.404-0.404-0.404H8.381c-0.222,0-0.404,0.182-0.404,0.404v1.214h-5.26c-0.223,0-0.405,0.182-0.405,0.405v9.71c0,0.223,0.182,0.405,0.405,0.405h14.566c0.223,0,0.404-0.183,0.404-0.405v-9.71C17.688,5.731,17.506,5.549,17.283,5.549 M8.786,4.74h2.428v0.809H8.786V4.74z M16.879,15.26H3.122v-4.046h5.665v1.201c0,0.223,0.182,0.404,0.405,0.404h1.618c0.222,0,0.405-0.182,0.405-0.404v-1.201h5.665V15.26z M9.595,9.583h0.81v2.428h-0.81V9.583zM16.879,10.405h-5.665V9.19c0-0.222-0.183-0.405-0.405-0.405H9.191c-0.223,0-0.405,0.183-0.405,0.405v1.215H3.122V6.358h13.757V10.405z"></path>
              </svg>
            </div>
            <h6 className="mb-2 text-lg font-bold leading-5 tracking-wider uppercase">
              Government
            </h6>
          </div>
          <div className="text-center">
            <div className="circle flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full sm:w-12 sm:h-12">
              <svg
                className="svg-icon
                w-8 h-8"
                fill="#005BEA"
                viewBox="0 0 20 20"
              >
                <path d="M18.737,9.691h-5.462c-0.279,0-0.527,0.174-0.619,0.437l-1.444,4.104L8.984,3.195c-0.059-0.29-0.307-0.506-0.603-0.523C8.09,2.657,7.814,2.838,7.721,3.12L5.568,9.668H1.244c-0.36,0-0.655,0.291-0.655,0.655c0,0.36,0.294,0.655,0.655,0.655h4.8c0.281,0,0.532-0.182,0.621-0.45l1.526-4.645l2.207,10.938c0.059,0.289,0.304,0.502,0.595,0.524c0.016,0,0.031,0,0.046,0c0.276,0,0.524-0.174,0.619-0.437L13.738,11h4.999c0.363,0,0.655-0.294,0.655-0.655C19.392,9.982,19.1,9.691,18.737,9.691z"></path>
              </svg>
            </div>
            <h6 className="mb-2 text-lg font-bold leading-5 tracking-wider uppercase">
              Healthcare
            </h6>
          </div>
          <div className="text-center">
            <div className="circle flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full  sm:w-12 sm:h-12">
              <svg
                className="svg-icon
                w-8 h-8"
                fill="#005BEA"
                viewBox="0 0 20 20"
              >
                <path d="M12.173,16.086c0.72,0,1.304-0.584,1.304-1.305V6.089c0-0.72-0.584-1.304-1.304-1.304c-0.721,0-1.305,0.584-1.305,1.304v8.692C10.868,15.502,11.452,16.086,12.173,16.086z M11.738,6.089c0-0.24,0.194-0.435,0.435-0.435s0.435,0.194,0.435,0.435v8.692c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V6.089zM16.52,16.086c0.72,0,1.304-0.584,1.304-1.305v-11.3c0-0.72-0.584-1.304-1.304-1.304c-0.721,0-1.305,0.584-1.305,1.304v11.3C15.215,15.502,15.799,16.086,16.52,16.086z M16.085,3.481c0-0.24,0.194-0.435,0.435-0.435s0.435,0.195,0.435,0.435v11.3c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V3.481z M3.48,16.086c0.72,0,1.304-0.584,1.304-1.305v-3.477c0-0.72-0.584-1.304-1.304-1.304c-0.72,0-1.304,0.584-1.304,1.304v3.477C2.176,15.502,2.76,16.086,3.48,16.086z M3.045,11.305c0-0.24,0.194-0.435,0.435-0.435c0.24,0,0.435,0.194,0.435,0.435v3.477c0,0.24-0.195,0.436-0.435,0.436c-0.24,0-0.435-0.195-0.435-0.436V11.305z M18.258,16.955H1.741c-0.24,0-0.435,0.194-0.435,0.435s0.194,0.435,0.435,0.435h16.517c0.24,0,0.435-0.194,0.435-0.435S18.498,16.955,18.258,16.955z M7.826,16.086c0.72,0,1.304-0.584,1.304-1.305V8.696c0-0.72-0.584-1.304-1.304-1.304S6.522,7.977,6.522,8.696v6.085C6.522,15.502,7.106,16.086,7.826,16.086z M7.392,8.696c0-0.239,0.194-0.435,0.435-0.435s0.435,0.195,0.435,0.435v6.085c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V8.696z"></path>
              </svg>
            </div>
            <h6 className="mb-2 text-lg font-bold leading-5 tracking-wider uppercase">
              Vending
            </h6>
          </div>
          <div className="text-center">
            <div className="circle flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full sm:w-12 sm:h-12">
              <svg
                className="svg-icon
                w-8 h-8"
                fill="#005BEA"
                viewBox="0 0 20 20"
              >
                <path
                  d="M14.584,1.617l-4.625,2.89L5.333,2.195L0.709,4.495v13.888l4.625-2.313l4.625,1.157l4.625-1.734l4.625,1.734
								V3.93L14.584,1.617z M18.053,15.492l-3.469-1.157l-4.625,1.734l-4.625-1.155l-3.468,1.734V5.086l3.468-1.734l4.625,2.312
								l4.625-2.891l3.469,1.734V15.492z M10.248,6.827c-0.16,0-0.29,0.163-0.29,0.363v6.781c0,0.201,0.129,0.363,0.29,0.363
								c0.16,0,0.289-0.162,0.289-0.363V7.19C10.537,6.99,10.408,6.827,10.248,6.827z M5.623,5.093c-0.16,0-0.29,0.163-0.29,0.363v7.938
								c0,0.201,0.129,0.363,0.29,0.363c0.16,0,0.289-0.162,0.289-0.363V5.456C5.912,5.256,5.783,5.093,5.623,5.093z M14.584,12.815
								c0,0.2,0.129,0.363,0.289,0.363s0.289-0.163,0.289-0.363V4.878c0-0.2-0.129-0.364-0.289-0.364s-0.289,0.164-0.289,0.364V12.815z"
                ></path>
              </svg>
            </div>
            <h6 className="mb-2 text-lg font-bold leading-5 tracking-wider uppercase">
              Tourism
            </h6>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 bg-[#FBFBFB] rounded-xl mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <h5 className="mb-2 mb-8 pb-14 text-2xl text-[#005BEA] text-center sm:text-2xl lg:text-5xl font-extrabold leading-none md:pl-2">
          Frameworks/Languages we are using
        </h5>
        <div className="grid gap-3 row-gap-3 text-[#005BEA]  pb-14 items-center lg:grid-cols-3">
          <ul className="space-y-3 pl-20">
            <li className="flex font-bold text-lg items-center">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              C
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              Spring/SpringBoot
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              Angular
            </li>
          </ul>
          <ul className="space-y-3 pl-20">
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              Jenkins
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              Java
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              Python
            </li>
          </ul>
          <ul className="space-y-3 pl-20">
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              JavaScript
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              HTML/CSS
            </li>
            <li className="flex items-start font-bold text-lg ">
              <span className="mr-1">
                <img src={check} className="pr-4" alt="checkmark"></img>
              </span>
              UniGUI/Delphi
            </li>
          </ul>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <div className="mb-6 sm:mx-auto"></div>
            <div className="max-w-2xl mb-10 md:mx-auto sm:max-w-md lg:max-w-xl md:mb-12">
              <h2 className="max-w-2xl mb-6 m-8  font-Manrope text-6xl font-bold text-white sm:text-4xl lg:text-5xl md:mx-auto">
                Ready to take your business to the next level?
              </h2>
              <p className="text-base text-white pl-6 sm:text-center sm:pl-6 md:text-lg">
                Contact us today to learn more about how we can help.
              </p>
            </div>
            <div className="sm:pl-6 pl-6">
              <a
                href="mailto:info@aikate.hr"
                className="contactbutton2 inline-flex  items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  fragment ServicesPageContent on ServicesPage {
    id
    heading
    text
  }
`
